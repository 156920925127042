import entireFront from "../../images/frontcover.jpg";
import entireBack from "../../images/back-cover.jpg";

export const productsList = [
  {
    name: "Idaho Gambling",
    desc: `A book for gamblers and readers alike`,
    price: 50.0,
    img: entireFront,
    backimg: entireBack,
    id: 100,
  },
];
