import React from 'react'
import './Header.css'

function Header() {
  return (
    <div className="header">
      <h1>IDAHO GAMBLING</h1>
      <p>THE EVOLUTION - LEGAL AND ILLEGAL</p>
    </div>
  )
}

export default Header
