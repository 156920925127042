import React from "react";
import "./Author.css";
import richard from "../../images/richard-jimenez.png";

function Author() {
  return (
    <div className="author">
      <h2>About the Author</h2>
      <figure className="figure3">
        <img className="rich" src={richard} alt="richard jimenez" />
        <figcaption>
          Richard "Rich" Jimenez
          <br />
          June 2016
          <br />
          Photograph by Judith Atchley
        </figcaption>
      </figure>
      <p>
        Richard Jimenez, became interested in collecting "old things" after
        being discharged from the Navy in 1965. He moved to Idaho Falls and found
        employment as an apprentice sheet metal worker; a career he followed
        until his retirement in 2002. His collecting began one day in 1967 when
        his older brother Bob, who owned a roofing business gave Richard a
        handful of old coins. Bob often took old furniture "in trade" in
        exchange for roofing jobs on peoples' homes. The coins came from the
        furniture drawers that held overlooked old stamps and coins.
      </p>
      <p>
        Richard was intrigued, bought a coin collecting book, looked up their
        value and was hooked. Some of them were worth many times their face
        value. His interest in anything old grew rapidly and he was soon
        collecting just about anything from anywhere. It didnt take long for him
        to realize that he had to limit the scope of his collecting.
      </p>
      <p>
        As a 14 year old boy, Richard's family had moved from Utah to Boise. The
        following year they moved to Idaho Falls for a year and then on to Twin
        Falls for a year where he graduated from high school. They were good
        years and he had grown to love Idaho. He turned his attention to items
        found in Idaho such as bottles, spoons, shot-glasses, postcards, tokens,
        currency, and much more. As his collection grew, he was captivated by
        the history behind each item.
      </p>
      <p>
        In 1971, he helped start the Eagle Rock Numisticmatic Society. In 1974,
        he became President. Since then, he has often been President or Vice
        President and is now a lifetime member. Over the years, he has also been
        a member of the Idaho Historical Society, Canada Numismatic Association,
        American Numistic Association and the National Token Collectors
        Association.
      </p>
      <p>
        In 1989, while attending a coin show in Boise, Idaho, Bill Gleixner, a
        friend and fellow collector, introduced Richard to Idaho poker chips. As
        Bill pointed out the different designs and colors of the chips and told
        stories that went with them, once again Richard was hooked.
      </p>
      <p>
        In his journeys around the state, Richard visited towns and businesses
        that had gambling. He talked to the people who lived during those times
        and took a lot of notes. In 1995, he bought his first book to help
        identify poker chips. The more he learned, the more chips he collected
        and the more he realized that there was a lot of gambling history many
        people didn't know existed. {" "}
        <strong>He began thinking about a book.</strong>
      </p>
      <p>
        In 2005, his passion for collecting poker chips and this stories
        motivated him to start scanning and organizing his chip collection in a
        binder. Whenever he could, he traveled around the state looking for
        gamblings paraphernalia and talked to anyone who has information about
        gambling. He found that most of the surviving relics of gambling were
        poker chips and a few punch boards. Most of the larger gambling devices
        such as roulette wheels, crap tables and slot machines had either been
        destroyed in the raids, or rotted away in forgotten storage. With
        increasing frequency, he found himself on the internet bargaining for
        Idaho poker chips, photographs, postcards, and match books.
      </p>
      <p>
        Finally, in early 2011, he dared to act upon what had then become a
        goal. To preserve some of Idaho's gambling history, he would write his
        own book. Since then, with the help and advice of his publisher,
        Richard's book has become a reality. The information that he has
        collected during the past 20 years can be found within the pages of this
        book. As a result, towns, clubs, and people along with their pictures,
        stories and gambling paraphernalia will live forever through
        <em> Idaho Gambling</em>.
      </p>
    </div>
  );
}

export default Author;
