import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink,
} from "react-router-dom";
import About from "../about/About";
import Author from "../author/Author";
import Home from "../home/Home";
import "./NavBar.css";
// import Products from "../products/Products.js";
// import Checkout from "../checkout/Checkout.js";


function NavBar() {
  return (
    <div>
      <Router>
        <ul className="links">
          <li>
            <NavLink exact to="/">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/about_book">About</NavLink>
          </li>
          <li>
            <a href="https://www.ebay.com/itm/193169674443">Buy</a>
          </li>
          <li>
            <NavLink to="/about_author">Author</NavLink>
          </li>
        </ul>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about_book">
            <About />
          </Route>
          {/* <Route
            exact
            path="/buy"
            render={() => (
              <Products />
            )}
          /> */}
          {/* <Route
            exact
            path="/checkout"
            render={() => (
              <Checkout />
            )}
          /> */}
          <Route path="/about_author">
            <Author />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default NavBar;
