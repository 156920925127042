import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div>
          <p>Idaho Falls, Idaho</p>
          <p>coinshtr73@gmail.com</p>
        </div>
        <div>
          <p>Home</p>
          <p>About Book</p>
          <p>Buy Book</p>
          <p>Author</p>
        </div>
      </div>
      <div>
        Copyright&copy;
        {new Date().getFullYear()}&nbsp;Idaho Gambling | All Rights Reserved
      </div>
    </div>
  );
}

export default Footer;
