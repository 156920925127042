import React from "react";
import "./About.css";
import headpicture2 from "../../images/headpicture2.jpg";
import { productsList } from "../products/ProductsList";

function About() {
  return (
    <div className="about-book">
      <h2>ABOUT THE BOOK</h2>
      <span className="book-cover-center">
        <img className="bookcover" src={productsList[0].img} alt="front cover" />
        <img className="bookcover" src={productsList[0].backimg} alt="front cover" />
      </span>
      <p>
        Since the days of the cavemen, people have gambled on just about
        anything and everything. Idaho Gambling contains a unique collection of
        stories and pictures of a bygone era in Idaho when gambling was legal,
        and not so legal. It is the only book in print, that combines the early
        history of gambling in the towns and their gambling establishments with
        the people who were a part of Idaho.
      </p>
      <img className="headpicture2" src={headpicture2} alt="idaho" />
      <p>
        Within the pages of this book is a rare collection of history taken from
        old newspapers and books. There are also stories told by the very senior
        residents of Idaho who lived and remembered some of the events.
        Collaboration of those stories by a second person was seldom possible
        because there was simply no one else left to tell the story. However,
        where "stretched" or "embellished" a little or not, they still provide
        insight and a flavor of how it was way back when there was gambling in
        just about every town in Idaho.
      </p>
      <p>
        <em>Idaho Gambling</em> also contains well over 1,000 photographs of
        towns, gambling establishments, people and gambling paraphernalia. Most
        of them are in color, the rest are in black and white. The older
        pictures of town scenes are reproductions taken from the author's
        collection of post cards and old photographs from various Idaho
        organizations and individuals. As such, some of them are a bit blurry,
        but still valuable reminders of how things have changed since the demise
        of gambling.
      </p>
      <p>
        In Chapter IV is a short history and interesting display of most of Idaho's
        Elks Lodges, a few Eagles Aeries (nests), and a very few VFW and
        American Legion posts. In this section, there are also appropriate
        pictures of meeting places and images of some of the poker chips used
        during the entertainment that often followed the meetings.
      </p>
      <p>
        The gambling paraphernalia displayed in the book consists of a few punch
        boards and a lot of poker chips. There are approximately 1,000 images of
        chips. Approximately 200 of them are doublefaced chips, or chips with
        the same markings on both sides. About 400 of them are of chips with a
        different image on each side which means there are two images of each
        chip. Also, in Appendix I, there is a display of all of the different 
        chip mold patterns found in the book and the supplier.
      </p>
      <p>
        For readers that have an interest in collecting, Appendix II
        contains a list of all of the known poker chips sold to people and
        places with gambling in Idaho. Many of the chips listed are found in
        this book, some are not.
      </p>
      <p>
        Finally, related to, but not directly a part of the gambling scene in
        Idaho, are entertaining copies of some of the original work by Cassius
        Marcellus Coolidge, 1844-1934. He was a prolific artist who produced a
        series of 16 interesting and fun pictures of dogs playing poker.
        Coolidge produced the original series for a cigar company. After all of
        these years, they can still be found and purchased as prints, posters,
        or gieleé reproductions.
      </p>
    </div>
  );
}

export default About;
