
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import NavBar from './components/navbar/NavBar'

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <NavBar />
        
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
