import React from "react";
import "./Home.css";
import frontPicture from "../../images/front-cover-picture.png";
import headPicture1 from "../../images/headpicture1.jpg";

function Home() {
  return (
    <div className="home">
      <div className="first-section">
        <figure className="figure1">
          <img className="frontpicture" src={frontPicture} alt="poker chips" />
          <figcaption>
            <h3 className="caption1">
              "A Smith and Weston beats four Aces every time."
            </h3>
            <p>- American Proverb</p>
          </figcaption>
        </figure>
        <div className="text">
          <h2>HISTORY OF GAMBLING</h2>
          <p>
            Gambling is probably one of the oldest forms of entertainment in the
            history of mankind. In China, artifacts have been found that
            indicate there was some forms of gambling as early as 2300 B.C. In
            Scandinavia around 100 A.D., two kings even used a game of chance to
            resolve a land dispute. Both King Olaf of Norway and King Olaf of
            Sweden claimed ownership of district containing a substantial amount
            of land. The Swedish king rolled double sixes back to back and won
            the territory.
          </p>
          <p>
            To gamble, is to wager something of value with the hope of winning
            back the wager and more. The outcome of the event may be purely
            random or maybe influenced by the players skill and knowledge in
            determining the odds of winning. Therefore, the knowledge of the
            game is very important when playing poker or betting on horse
            racing, but is of little use when rolling dice, playing roulettes, slot
            machines, or purchasing lottery tickets.
          </p>
          <p>
            In the movie <em>"Cool Hand Luke"</em>, Paul Newman best
            characterized this principal when he said, "If you are playing a
            poker game and you look around the table and can't tell who the
            sucker is, it's you."
          </p>
          <p>
            No one knows exactly when gambling started in North America. When
            the first trappers came west, the Native Americans had developed
            their own form of gambling. Stories passed down by the first
            trappers, indicated that a favorite of the Indians during
            rendezvous' in the 1800's was the bone game.
          </p>
          <p>
            The game consisted of spreading a tanned hide on the ground, and
            like tossing dice, they would shake and toss bones. The bones were
            shaped and painted with different symbols such as lightning bolts,
            tepees, animals, stars and birds. Each symbol was assigned a value.
            The value of a symbol in a low stake game might be feathers, shells,
            beads, arrow heads, etc. In higher stake games, the value of the
            symbols could be different types of furs, knives, or even guns,
            taken or won from early trappers.
          </p>
        </div>
      </div>
      <br />
      <hr />
      <br />
      <div className="second-section">
        <span className="poem">
          <h3>GAMBLING</h3>
          <p className="poem-lines">
            Some may consider it a vice,
            <br />
            I surely wouldnt take their advice.
            <br />
            For it's not the gambling I love,
            <br />
            It's collecting the items gamblers utilize.
            <br />
            For me to see a pearl poker chip,
            <br />
            A dust covered one arm bandit,
            <br />
            Or a yellowed, ivory pair of dice,
            <br />
            Will propel me to paradise.
            <br />
            It's not gambling's mystery.
            <br />
            It is in fact the history,
            <br />
            I surely do realize,
            <br />
            It is not a game of chance.
          </p>
          <p className="poem-author">-Courtesy of Judith Atchley</p>
        </span>
        <div className="figure2">
          <img
            className="headpicture1"
            src={headPicture1}
            alt="old slot machine"
          />
        </div>
      </div>
      <br />
      <hr />
      <br />
      <div className="third-section">
        <h2>EVOLUTION AND USE OF CHIPS</h2>
        <p>
          As the popularity of gambling games grew, so did the need to simplify
          wagers, pay off players and close out a game. The use of poker chips
          became the logical choice. Chips had some unique defining
          characteristics and could be assigned one value, while chips with a
          different color and/or markings, could be assigned another.
        </p>
        <p>
          In the very early history of gambling, the first chips were mostly
          hand made from wood, bone, ivory, mother-of-pearl and even paper.
          Chips made from bone were mostly carved from cow bones and were coarse
          and grainy. Ivory and mother-of-pearl chips were in an elegant class
          of their own. There were silky to the touch and sometimes ornately
          designed.
        </p>
        <p>
          The first mother-of-pearl chips were made in China from 1750-1890 and
          later in France. Ivory chips were also made in China from 1830 through
          1910. They were first used on the North American continent during the
          mid-1800's in New Orleans poker games. By the 1870's, they were the
          favorite chips used by the Mississippi river boat gamblers.
        </p>
      </div>
      <br />
      <hr/>
      <br />
      <div className="fourth-section">
        <h2>DISCLAIMER</h2>
        <p>
          Stories about gambling in Idaho seem to be endless. As is often
          the case when stories are passed down orally through the years, new
          versions of the same event may appear. Whenever possible, the stories
          contained herein this book have been verified by more than one source.
          Therefore, to the best of the author's knowledge, the stories and
          events as reported herein are true. Every effort has been made to
          provide complete and accurate information. The purpose of this book is
          to capture and preserve a part of Idaho's history. It is also meant to
          be educational and entertaining.
        </p>
        <h4>If you enjoy gambling and love to read, this book is for you.</h4>
      </div>
    </div>
  );
}

export default Home;
